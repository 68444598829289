import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components
import HeroChevron from "../hero/hero-chevron";
import BreadcrumbAuto from "../breadcrumb/breadcrumb-auto";
import SEO from "../seo/seo";
import BranchSearch from "../branch-search/branch-search";
import BestBanksDefault from "../best-banks/best-banks-default";
import NoLinkCards from "../../components/card/no-link-cards";

// Helpers
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

export const query = graphql`
  query cityHeroQuery(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
    $pathXXS: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXXS }) {
      ...heroChevronImageFragmentXXS
    }
    checkingImage: file(relativePath: { eq: "cards/thumbnail-checking-030524.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    savingsImage: file(relativePath: { eq: "cards/thumbnail-savings-030524.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    mortgageImage: file(relativePath: { eq: "cards/thumbnail-home-loans-030524.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    cityBranches: strapiBranchCities(Slug: { eq: $slug }) {
      CityName
      PageURL
      Slug
      SEO {
        MetaDescription
        MetaTitle
      }
      branch_locations {
        Address {
          State
        }
      }
    }
  }
`;

const CityBranchesTemplate = ({ location, data }) => {
  const cityData = data.cityBranches;
  const CityName = cityData.CityName || "Seattle";
  let StateName = cityData.branch_locations[0].Address.State || "Washington";
  StateName = StateName.replace(/NewMexico/, "New Mexico");

  const SearchData = {
    showSearchInput: false,
    CityName: CityName,
    StateName: StateName,
    title: CityName
  };

  const pageLocation = { location };

  const title = CityName + ", " + StateName + " Locations - Best Bank in " + CityName;
  const defaultMetaDescription =
    "Discover WaFd Bank in cities across 9 western States, offering checking, savings accounts, " +
    "business banking, home  and construction loans, and more. See why we were voted best bank!";

  const seoStateName = StateName.toLowerCase().replace(" ", "-");

  const SEOData = {
    title,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: cityData.SEO.MetaDescription || defaultMetaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com" + cityData.PageURL
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content:
          "https://www.wafdbank.com/images/adsearch/cities/og-" + cityData.Slug + "-" + seoStateName + "-052024.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "state-branches-hero-chevron",
    ...getHeroImgVariables(data),
    altText: `Photo of ${CityName}, ${StateName} skyline.`,
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: `Find Your Local WaFd Bank in ${CityName}, ${StateName}!`
          }
        },
        {
          id: 2,
          button: {
            id: "state-branches-hero-chevron-cta",
            text: "Open an Account",
            url: "/personal-banking/checking-account/rewards-checking",
            class: "btn-white"
            // containerClass: "mb-3"
          }
        }
      ]
    }
  };

  const threeCardsData = {
    sectionId: "state-branches-link-cards",
    cards: [
      {
        id: "state-branches-link-card-checking",
        title: "Checking That's Simple, Straightforward, Smart",
        titleClass: "text-success",
        text: "Your account should work hard for you. From standard tools like Bill Pay to benefits and rewards, you'll find a checking account to fit your needs. Plus, our great-looking debit cards will help you spend in style!",
        image: {
          imgVariable: data.checkingImage.childImageSharp.gatsbyImageData,
          altText: "Woman on her mobile phone while sitting cross-legged on a couch."
        },
        button: {
          id: "state-branches-link-card-checking-cta",
          text: "Compare Accounts",
          url: "/personal-banking/checking-account",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "state-branches-link-card-savings",
        title: "Savings Accounts to Help You Thrive",
        titleClass: "text-success",
        text: "With a variety of savings options, your money can work harder for you. We offer various options to fit your needs with the flexibility life requires.",
        image: {
          imgVariable: data.savingsImage.childImageSharp.gatsbyImageData,
          altText: "A mother and young son on vacation, Copenhagen train station."
        },
        button: {
          id: "state-branches-link-card-savings-cta",
          text: "Compare Accounts",
          url: "/personal-banking/savings-account",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "state-branches-link-card-mortgage",
        title: "Home Loans to Fit Your Life",
        titleClass: "text-success",
        text: "Wherever you are in life, a home loan should fit your needs. With first time home buyer, remodel, construction, and more options, our knowledgeable loan officers are here for you.",
        image: {
          imgVariable: data.mortgageImage.childImageSharp.gatsbyImageData,
          altText: "A happy couple sitting on their couch, laughing."
        },
        button: {
          id: "state-branches-link-card-mortgage-cta",
          text: "Learn More",
          url: "/personal-banking/home-loans",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      }
    ]
  };

  const isBestBankState = ["Washington", "Idaho", "New Mexico"].includes(StateName);

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <BreadcrumbAuto {...pageLocation} />

      <div id="state-branches-wrapper">
        <section id="state-branches-overview-section" className="container">
          <h1>
            WaFd Bank in {CityName}, {StateName}
          </h1>
          <h3 id="state-branches-best-bank-intro">
            {isBestBankState}
            Find your local WaFd Bank in {CityName}, {StateName}. WaFd{" "}
            {isBestBankState
              ? "was voted Best Bank in " + StateName
              : "has been voted Best Bank by Newsweek three years in a row,"}{" "}
            and is here to serve you and our communities in 9 western states for all your banking needs. Whether you're
            looking for a{" "}
            <Link id="state-branches-open-bank-account-online-link" to="/open-bank-account-online">
              bank account
            </Link>
            , a home{" "}
            <Link id="state-branches-home-loans-link" to="/personal-banking/home-loans">
              mortgage
            </Link>{" "}
            or{" "}
            <Link id="state-branches-construction-loans-link" to="/personal-banking/home-loans/construction-loans">
              construction loan
            </Link>
            ,{" "}
            <Link
              id="state-branches-using-home-equity-heloc-vs-home-equity-loan-link"
              to="/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan"
            >
              home equity
            </Link>
            ,{" "}
            <Link id="state-branches-small-business-link" to="/business-banking/small-business">
              business banking
            </Link>
            ,{" "}
            <Link id="state-branches-online-banking-link" to="/personal-banking/online-banking">
              digital banking tools
            </Link>{" "}
            and more, WaFd Bank is here to help. With our online and mobile banking experience, you can open an account
            or bank with us anytime, anywhere.
          </h3>
        </section>

        <BranchSearch {...SearchData} />
        <NoLinkCards {...threeCardsData} />
        <BestBanksDefault />
      </div>
    </SecondaryLanding>
  );
};

CityBranchesTemplate.propTypes = {
  branch: PropTypes.object
};

export default CityBranchesTemplate;
